<template>
    <div
            class="block-separator"
            :style="{ height: `${content.size}px` }"
            :class="[{ full: content.full }, { 'has-fading': content.fading }, { 'has-icon': content.icon }]"
    >
        <span v-if="content.type !== 'none'">
            <v-icon v-if="content.icon" :src="content.icon"/>
        </span>
    </div>
</template>

<script>
    import VIcon from "./VIcon";
    export default {
        name: "BlockSeparator",
        components: {
            VIcon
        },
        props: {
            content: {}
        }
    };
</script>

<style scoped lang="scss">
    .block-separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;

        span {
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 49%;
                width: 20%;
                height: 1px;
                background: #000;
                opacity: 0.3;
            }

            &:before {
                transform: translateX(-100%);
            }
        }

        &.has-icon {
            span:before {
                transform: translateX(-115%);
            }

            span:after {
                transform: translateX(15%);
            }
        }

        &.full {
            span:after,
            span:before {
                width: 45%;
            }
        }

        &.has-icon.full {
            span:after {
                right: 0;
                transform: translateX(0);
            }

            span:before {
                left: 0;
                transform: translateX(0);
            }
        }

        &.has-fading {
            span {
                &:before {
                    background: linear-gradient(to left, #000 0, rgba(255, 255, 255, 0) 100%);
                }

                &:after {
                    background: linear-gradient(to right, #000 0, rgba(255, 255, 255, 0) 100%);
                }
            }
        }
    }
</style>
