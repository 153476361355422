<template>
  <svg :class="`svg-icon icon-${src} ${css}`">
    <use v-bind="{ 'xlink:href': `${symbolDefs}#icon-${src}` }"></use>
    <!--        <use :xlink:href="`${symbolDefs}#icon-${src}`"></use>-->
  </svg>
</template>

<script>
  export default {
    name: "VIcon",
    props: {
      css: {
        default: ""
      },
      src: {
        required: true
      }
    },
    computed: {
      symbolDefs() {
        return require("../assets/icons/icons.svg");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .icon-builder-start {
    width: 352px;
    height: 258px;
  }
  .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: top;
  }
</style>
